.banner-img {
  background-color: #f2f2f2;
}
.search-home {
  position: relative;
  // width: 97%;
  width: 540px;
  border-radius: 10px;
  margin: 0 auto;

  .search-icon {
    // position: absolute;
    // top: 52%;
    // left: 20px;
    // transform: translateY(-50%);
    position: absolute;
    top: 50%;
    // left: -40px;
    transform: translateY(-50%);
    padding: 17px 14px 13px 19px;
    background-color: #dadada;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .search-icon i {
    font-size: 25px;
    color: #bbbbbb;
  }
  & input {
    padding-left: 57px;
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 10px;
  }
}

@media (min-width: 1480px) {
  .search-home {
    width: 540px;
  }
}

@media (max-width: 1480px) {
  .search-home {
    width: 476px;
  }
}

@media (max-width: 768px) {
  .search-home {
    width: 90%;
    font-size: 11px;
  }
}


.banner-text-blue {
  background-color: #122142;
  width: max-content;
  color: #fff;
  padding: 19px 100px;
  border-radius: 10px 50px 10px 50px;
  margin: 30px auto 30px auto;
}
.banner-photo {
  width: calc(100% - 610px);
  float: right;
}
.box {
  display: flex;
  .content-text {
    height: 80vh;
    width: 100%;
  }

  .adjustFixed {
    height: 80vh;
  }
  .mulher {
    position: absolute;
    left: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.main-slider.style-1 .banner-content .content-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px 100px 0px !important;

  .banner-description {
    text-align: center;
    width: 90vw;
    max-width: 1018px;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 1300px) {
  .content-text {
    width: 100% !important;
    height: auto !important;
    
  }
  .box {
    flex-direction: column;
    .mulher {
      height: 80vh !important;
      background-color: #8f8f8f00 !important;
    }
  }
  .banner-photo {
    height: inherit !important;
    width: 100%;
  }
  .main-slider.style-1 .banner-content .content-info {
    padding: 25px 15px 100px 15px !important;
    font-size: 1.3rem !important;
    height: auto;
    
    .title {
      font-size: 1.5rem !important;
    }
  }
}

.content-text {
  position: relative;

}

// .content-text:after {
//   content:'';
//   background: url('https://www.10wallpaper.com/wallpaper/1366x768/1405/Forest_trail-Landscape_HD_Wallpaper_1366x768.jpg') no-repeat;
//   position: absolute;
//   top:0px;
//   left: 0px;
//   width:100%;
//   height:100%;
//   background-size: cover;
//   opacity: 0.2; /* Here is your opacity */
// }
.cursive-footer-banner {
  bottom: 0;
  position: absolute;
  z-index: 5;
}


.banner-info-mob {
  display: none;
}
.banner-photo {
  background-size: contain;
  background-position: bottom left;
  background-repeat: no-repeat !important;
  height: 90vh;
  .orange-bar {
    height: 7px;
    width: 75%;
    border-radius: 5px;
    background-color: #e3cac0;
    margin: 0 auto 10px auto;
  }
}

.main-slider {
  overflow: hidden;
  position: relative;
  .swiper-slide {
    height: 100% !important;

    overflow: hidden;
  }
  .main-swiper-thumb {
    position: absolute;
    right: 0;
    bottom: 60px;
    z-index: 99;
    width: 45vw;
    height: auto;
    overflow: hidden;
    padding: 20px 20px 20px 0;
    .swiper-wrapper {
      height: auto;
    }
    .swiper-slide {
      opacity: 0.6;
      width: 380px;
      @include transitionSlow;

      &.swiper-slide-active {
        opacity: 1;
        .books-card {
          box-shadow: 0px 7px 24px 0px rgba(17, 12, 46, 0.15);
        }
      }
    }
    .books-card {
      background-color: #fff;
      border-radius: var(--border-radius-base);
      display: flex;
      align-items: center;
      padding: 15px;
      .dz-media {
        width: 85px;
        min-width: 85px;
        margin-right: 20px;
      }
      .dz-content {
        .dz-meta {
          ul {
            li {
              color: var(--secondary);
              font-size: 14px;
              font-weight: 400;
              font-family: var(--font-family-title);
            }
          }
        }
        .book-footer {
          display: flex;
          justify-content: space-between;
          .price {
            .price-num {
              font-family: var(--font-family-title);
              font-size: 20px;
              color: var(--title);
              font-weight: var(--headings-font-weight);
            }
          }
        }
      }
    }
    @include respond("tab-port") {
      display: none;
    }
  }
  &.style-1 {
    .swiper-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 60px 0;
    }
    .swiper-pagination-wrapper {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      z-index: 999;
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 600px) {
        top: 100%;
        left: 10%;
      }
    }
    .banner-content {
      // padding-top:30px;
      .content-info {
       
        padding: 80px 0px 30px 10px;
        z-index: 2;
        position: relative;
        color: #122142 !important;
        font-size: 1.5rem;
        text-align: center;
        img {
          width: 550px;
          padding-bottom: 20px;
        }
      }
      .sub-title {
        color: #f0bbe3;
        line-height: 1.6;
        letter-spacing: 20px;
        font-weight: var(--headings-font-weight3);
      }
      .title {
        color: #122142;
        font-size: 1.8rem;
        margin: 20px 0 0 0;
        font-weight: lighter;
      }
      .dz-tags {
        margin-bottom: 35px;
        line-height: 1.5;
        display: inline-flex;
        li {
          a {
            font-size: 1.125rem;
            margin-right: 20px;
            color: $glight;
            font-weight: var(--headings-font-weight2);
          }
        }
      }
      .text {
        padding-left: 32px;
        color: rgba(255, 255, 255, 0.8);
        border-left: 1px solid var(--primary);
        font-weight: var(--headings-font-weight2);
      }
      .price {
        margin: 25px 0px 25px;
        display: inline-flex;
        align-items: center;
        .price-num {
          margin-bottom: 0px;
          font-size: 30px;
          font-weight: 600;
          color: var(--primary);
        }
        del {
          font-family: var(--font-family-title);
          font-style: normal;
          font-weight: var(--headings-font-weight2);
          color: $glight;
          margin-bottom: 0;
          font-size: 18px;
          padding: 0 10px;
        }
      }
      .partner {
        p {
          color: #9290b0;
          font-weight: var(--headings-font-weight2);
        }
        .brand-logo {
          .mid-logo {
            margin: 0 60px;
          }
        }
      }
    }
    .banner-media {
      position: relative;
      z-index: 1;
      width: 720px;
      float: left;
      margin-top: -50px;
      padding: 10px 100px 100px 0;

      img {
        width: 100%;
      }
    }
    .pattern {
      position: absolute;
      top: 32%;
      left: 38%;
    }
    &.slider-white {
      .banner-content {
        .sub-title {
          color: var(--primary);
        }
        .title {
          color: var(--secondary);
        }
        .dz-tags {
          li {
            a {
              color: var(--secondary);
            }
          }
        }
        .text {
          border-left: 1px solid #3b2f4a;
          color: var(--secondary);
          @media only screen and (max-width: 600px) {
            border: 0;
          }
        }
        .price {
          del {
            color: #5f5c9d;
          }
          .badge {
            background-color: #ff1e6f;
          }
          @include respond("phone") {
            display: none;
          }
        }
      }
      .banner-media {
        &:after {
          background-color: var(--primary);
        }
      }
    }
    @include respond("tab-land") {
      .banner-content {
        // padding-top: 50px;
        .content-btn {
          margin-bottom: 0;
        }
        .title {
          font-size: 2.5rem;
        }
      }
      .banner-media {
        width: 650px;
        // padding: 0 0 20px 0;
        // margin-top: 0;

        &:after {
          transform: translateX(-50%) scale(0.9);
        }
      }
    }
    @include respond("tab-port") {
      .banner-info-desk {
        display: none;
      }
      .banner-info-mob {
        display: block;
      }
      .banner-photo-mob {
        display: block;
      }
      .banner-media {
        width: auto;
        padding: 0 100px 20px 100px;
        &:after {
          display: none;
        }
      }
      .pattern {
        display: none;
      }

      .swiper-content {
        padding: 0px;
      }
      .banner-content {
        .title {
          font-size: 2rem;
        }
        .sub-title {
          letter-spacing: 5px;
        }
        .dz-tags {
          margin-bottom: 20px;
          li a {
            font-size: 14px;
          }
        }
        .text {
          padding-left: 13px;
          font-size: 15px;
        }
        .price {
          margin: 15px 0px;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      .banner-content {
        .sub-title {
          letter-spacing: 10px;
        }
        .partner {
          display: none;
        }
      }
    }
    @include respond("phone-land") {
      .banner-content {
        .text {
          padding-left: 0;
          padding-right: 0;
          border: 0;
        }
        .price {
          display: none;
        }
        .content-btn {
          margin-top: 30px;
        }
      }
    
      .banner-text-blue {
        width: 90vw;
        padding: 19px 50px;
        margin: 20px auto 30px auto;
      }
      .banner-media {
        img {
          padding: 0;
          left: 219px;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .banner-media {
        left: 18%;
      }
    }
    @include respond("phone") {
      .banner-media {
        left: 0%;
      }
      .banner-content {
        padding-top: 30px;
        .sub-title {
          font-size: 14px;
        }
        .title {
          margin: 30px 0 30px 0;
          font-size: 1.7rem;
          // margin-bottom: 10px!important;
          margin: 15px 0;
        }
        .dz-tags {
          display: none;
        }
      }
    }
  }
  &.style-2 {
    background-color: #fcfcfc;
    .banner-content {
      .content-info {
        padding-bottom: 25px;
      }
      .sub-title {
        line-height: 1.6;
        letter-spacing: 10px;
        font-weight: var(--headings-font-weight3);
      }
      .title {
        color: var(--secondary);
        font-size: 3.75rem;
        margin-bottom: -5px;
      }
      .sub-head {
        line-height: 2;
        font-size: 2.5rem;
        font-weight: var(--headings-font-weight);
        font-family: var(--font-family-title);
        color: var(--title);
        margin-bottom: 28px;
      }
      .para {
        font-weight: var(--headings-font-weight2);
        line-height: 1.5;
        font-size: 14px;
        width: 512px;
        margin-bottom: 65px;
      }
      .partner {
        margin-bottom: 60px;
        p {
          color: #9290b0;
          font-weight: var(--headings-font-weight2);
          margin-bottom: 28px;
        }
        .brand-logo {
          .mid-logo {
            margin: 0 60px;
          }
        }
      }
      .content-btn {
        margin-bottom: 85px;
        img {
          position: absolute;
          left: -27px;
          bottom: 45%;
        }
      }
    }
    .banner-media {
      position: relative;
      z-index: 1;
      bottom: 0;
      &:after {
        content: "";
        position: absolute;
        width: 750px;
        height: 750px;
        border-radius: 50%;
        bottom: -130px;
        left: 44%;
        background-color: #f0bbe3;
        z-index: -1;
        opacity: 0.5;
        transform: translateX(-50%);
      }
      img {
        max-width: 810px;
        position: absolute;
        bottom: 0;
        left: -86px;
      }
    }
    @include respond("laptop") {
      .banner-media {
        &:after {
          width: 650px;
          height: 650px;
          left: 47%;
        }
        img {
          max-width: 770px;
          left: -100px;
        }
      }
    }
    @include respond("tab-land") {
      .banner-media {
        &:after {
          width: 600px;
          height: 600px;
          left: 47%;
        }
        img {
          max-width: 600px;
          left: -22px;
        }
      }
      .banner-content {
        .partner {
          display: none;
        }
        .content-btn {
          img {
            left: -6px;
            bottom: 30%;
          }
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      .banner-media {
        &:after {
          width: 550px;
          height: 550px;
        }
        img {
          left: -91px;
        }
      }
      .banner-content {
        .title {
          font-size: 2.7rem;
        }
        .sub-head {
          font-size: 2rem;
        }
        .para {
          width: 400px;
        }
      }
    }
    @include respond("tab-port") {
      .banner-media {
        &:after {
          display: none;
        }
        img {
          max-width: 420px;
          left: 329px;
        }
      }
      .banner-content {
        .content-info {
          padding-bottom: 142px;
        }
        .para {
          width: 512px;
        }
      }
    }
    @include respond("phone-land") {
    
      .banner-content {
        .content-info {
          padding-bottom: 200px;
        
        }
        .sub-head {
          font-size: 1.5rem;
          margin-bottom: 0;
        }
        .para {
          margin-bottom: 25px;
        }
      }
      .banner-media {
        img {
          left: 75px;
        }
      }
    }
    @include respond("phone") {
      .banner-content {
        .content-info {
          padding-bottom: 250px;
        }
        .title {
          font-size: 2rem;
        }
        .para {
          width: auto;
        }
      }
      .banner-media {
        img {
          max-width: 370px;
          left: -15px;
        }
      }
    }
  }
}

@include respond("phone-land") {
  .banner-description {
    text-align: center!important;
    width: 90vw!important;
    margin: 0 auto 30px auto;
  }
}
