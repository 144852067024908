.box-coupons {
    border: 1px solid;
    text-align: center;
    border-radius: 5px;
    position: fixed;
    right: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 600px;
    min-height: auto;
    box-shadow: 0px 1px 11px 0px #626262;
    padding: 20px 20px 20px 20px;
}
    .MuiPagination-ul{
        justify-content: center!important;
        padding-bottom: 50px!important;

    }