//Modal Payments
.container-modal-payments {
    position: fixed;
    top: 0;
	left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	z-index: 9999;
	// background-color: rgb(0 0 0 / 30%);

	.layout-payments {
		background-color: #fff;
		border: none;
		padding: 20px;
		width: 80vw;
		height: auto;
		box-shadow: 0px 0px 3px 1px #bababa;
	}
	.btn-cancel-payment {
		float: right;
	}
}



//PDF MODAL
.container-pdf {
    border: 1px solid white;
    position: fixed;
    left: 50%;
    width: 90%;
    height: 90%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    background-color: #fbfbfb;
    top: 50%;
    box-shadow: 0px 0px 4px 1px #8f8f8f;
    border-radius: 6px;
}
.close-pdf {
	display: flex;
	justify-content: right;
	p {
		color: #fff;
		background: #f1aaaa;
		padding: 5px 10px;
		width: max-content;
		border-radius: 3px;
		&:hover {
			background: #e09b9b;
			cursor: pointer;
		}
	}
	&::-webkit-scrollbar {
		width: 0px;
	}
}



.inquiry-modal{
	.modal-dialog{
		max-width: 700px;
		display: flex;
		min-height: auto;
		justify-content: stretch;
		align-items: stretch;
	}
	.form-control{
		font-size: 15px;
		font-weight: 300;
		color: #000;
	}
	.inquiry-adv{
		flex: 0 0 50%;
		max-width:50%;
		@include respond('phone-land'){
			display:none;
		}
		img{
			height:100%;
			object-fit:cover;
			width:100%;
		}
	}
	.modal-content{
		border: 0;
		border-radius: 0;
		background: #fff;
		padding: 30px;
		flex: 0 0 50%;
		max-width:50%;
		@include respond('phone-land'){
			flex: 0 0 100%;
			max-width:100%;
			padding: 20px;
		}
		.modal-header{
			border:0;
			padding:0;
			display: block;
			text-align: center;
			
			.modal-title{
				font-size: 24px;
				margin-bottom: 15px;
				line-height: 1.3;
				font-family: var(--font-family-title);
				text-align: center;
				width: 100%;
			}
			i{
				color: var(--primary);
				font-size: 54px;
				line-height: 1;
				display: inline-block;	
			}
		}
		.modal-body{
			padding:0;
		}
	}
	.btn-close {
		margin: 0;
		position: absolute;
		right: -40px;
		top: -40px;
		color: #fff;
		font-weight: 100;
		text-shadow: none;
		opacity: 1;
		font-size: 40px;
		padding: 0;
		height: 40px;
		line-height: 40px;
		width: 40px;
		border: 0;
		background: transparent;
		@include respond('phone-land'){
			right: 0;
		}
	}
}

.visibility-mobile {
	label {
		display: none;
	}
}
//MODAL CADERNOS

.item-1-modal { width: 45% ;}

@include respond('phone'){

	.visibility-mobile {
		label {display: block; font-weight: 600;}
		tr {display: grid;}

		.item-1-modal {
			grid-column-start: 3;
			grid-column-end: 1;
		    grid-row-end: 2;
			width: 100% ;
			margin-top: 10px;
			border-top: 1px solid #dfdfdf;
		}
		.item-3-modal {
			grid-column-start: 2;
		    grid-row-end: 3;
		}
		.item-2-modal {
			grid-column-start: 1;
			grid-row-end: 3;
		}
		.item-4-modal {
			grid-column-start: 1;
			grid-column-end: 3;
			grid-row-start: 3;
			background-color: #ededed;
			padding: 5px;
		}
	}
	.visibility-mobile {
		tr {
			width: 100%;
			flex-direction: column;
			th:nth-child(2){display: none;}
			th:nth-child(3){display: none;}
			th:nth-child(4){display: none;}
		}
	}
}


.opacity { color: #A5A5A5!important; }
.center { text-align: center; }

.check-tbl tbody td {
	input , textarea {
		width: 100%;
		border: none;
		text-align: center;
	}
	textarea  {
		// min-height: 65px!important;
   		//  max-height: 55%!important;
	}
}


.background-black {
    z-index: 9999;
	background-color: rgba(0, 0, 0, 0.411);
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
}
.modalBooks {
	overflow-y: auto;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 90vw;
	height: 80vh;
	z-index: 9999;
    border: 1px solid #000;
    background-color: white;
    box-shadow: 0px 0px 7px 6px #606060;
	.closeBtn {
		font-size: 20px;
		text-align: right;
		padding: 10px 23px;
		font-weight: bolder;
		font-family: 'Font Awesome 6 Free';
		color: #b3b3b3;
		cursor: pointer;
		& :hover {
			font-family: 22px;
		}
	}
	.table {
		width: 95%;
    	margin: 0 auto;
		img {
			min-width: 25px;
			max-width: 25px;
		}
	}

	.hover-btn {
		filter: opacity(0.5);
		transition: all .2s;
		&:hover {
			cursor: pointer;
			transition: all .2s;
			filter: opacity(1);
		}
	}
}

.addMore {
	display: flex;
	justify-content: space-between;
	img {
		padding: 13px 30px 13px 0;
		position: relative;
	}
}