


.dz-social-icon{
	ul {
		display: flex;
		justify-content: center;
	}
	li{
		display:inline-block;
		margin-right: 5px;
		a{
			border-radius:40px;
			@include transitionMedium;
		}
	}
	&.style-1{	
		margin-top:10px;
		// border-bottom: 1px solid rgb(233, 233, 233);
		li{
			margin: 0 10px 10px 10px;
			color:#000;
			a{
				font-size: 23px;
				color: var(--primary);
				background-color: var(--rgba-primary-1);
				width:60px;
				height:60px;
				line-height:40px;
				display:flex;
				align-items: center;
				justify-content: center;
				&:hover{
					background-color:var(--primary);
					color:#fff;
				}
			}
		}
		@include custommq($max:1191px){
			justify-content: center;
			li {
				margin: 0 20px 10px 20px;
			}
		}
	}
	&.style-2{
		li{
			a{
				&.twitter {
					background-color: $twitter;
				}
				&.facebook {
					background-color:  $facebook;
				}
				&.instagram {
					background-color: $instagram;
				}
				&.youtube {
					background-color: $youtube;
				}
				&:hover{
					color:#fff;
					background-color:var(--primary);
				}
			}
		}
	}
	&.style-3{
		li{
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align:center;
			border-radius:var(--border-radius-base);
			&:nth-child(1){
				background-color: $facebook;
			}
			&:nth-child(2){
				background-color: $twitter;
			}
			&:nth-child(3){
				background-color: $whatsapp;
			}
			&:nth-child(4){
				background-color: $google-plus;
			}
			a{
				color:$white;
				font-size: 16px;
				line-height: 1;
			}
		}
		@include respond('phone'){
			display:none;
		}
	}
}