.react-datepicker > button, 
.react-datepicker__header,
.react-datepicker__week,
.react-datepicker__tab-loop  {
  display: none!important;
}

.pagination-my-books {
  ul {
    padding: 30px 0 0 0!important;
  }
}

.title-court {
  text-align: left!important;
  }

.search-books-modal {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid;
  padding: 4px;
  border-radius: 4px;
  margin-right: 6px;
}

.tooltip-inner {
  width: 100%!important;
  min-width: 100%!important;
}

.historic_line {
  display: grid;
  grid-template-columns: 240px 1fr;
}

.no-cursor {
  cursor: not-allowed!important;
}
.no-hover {
  &:hover {
    background-color: #e9f4f3!important;
  }
}
.btn-save-cad {
  position: absolute;
  right: 3px;
  top: 4px;
  border: 1px solid;
  width: 26px;
  border-radius: 3px;
  font-weight: 100;
}
.form-info {
  display: flex;
  justify-content: space-between;
  padding: 0!important;
  .info-header {
    & :nth-child(1){ 
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & :nth-child(2){ 
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.form-layout-court {
  // width: max-content;
  border-radius: 3px;
  box-shadow: 0px 1px 1px #aeaeae;
  background-color: #e4e4e4;
}
.removeIcon > .accordion-button::after {
  display: none!important;
}
.espacinho {
  padding-right: 17px;
}
.checked {
  background-color: #0032ff;
  color: #fff;
}
.studyDays {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  p {
    border: 1px solid #ababab;
    padding: 5px 10px;
    border-radius: 5px;
  }

}

//CADERNOS ADQUIRIDOS
.flex-my-books {
  display: grid;
  grid-template-columns: 1fr 127px;
  border-bottom: 1px solid #dddddd;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .flex-my-books {
    grid-template-columns: 1fr;
  }
  .form-info {
    flex-direction: column;
    // button {
    //   margin-top: 10px;
    // }
  }
}


//NOVA TELA DE BOOKS REVISION
.actions-books {
  display: flex;
  justify-content:space-between;
  align-items: center;
  button {
    padding: 5px;
  }
}


.box-search {
  position: relative;
  width: 100%;
  input {
    width: 100%;
  }
}
.ant-select-selector {
  width: 100%!important;
}
.historic-topbar {
    display: grid;
    grid-template-columns: 1fr 67px;
    padding: 0px 0px 23px 0;
    align-items: center;
    font-weight: bold;
}
.container-modal-books {
  background-color: rgba(0, 0, 0, 0.115);
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  box-shadow: 0px 0px 2px 3px #a5a5a54d;
  select {
    width: 200px;
  }
  option {
  border-radius: 0;
  }
  input, select {
    border-radius: 3px;
    border: 1px solid #9b9b9b;
    padding: 6px;
  }
  button {
    float: right;
  }
}
.create-modal-layout {
  background-color: white;
  padding: 20px;
  border: 1px solid white;
  display: flex;
  max-width: 766px;
  min-width: 350px;
  width: 74vw;
  flex-direction: column;
}
.weight { 
  font-weight: 600;
}

.bg-modal {
  padding: 20px;
}
.btn-top-revisions {
    border: 1px solid #dbdbdb;
    background-color: #fafafa;
}
.revisions-top-bar {
  display: grid;
  align-items: center;
  grid-template-columns: 65px 1fr 116px 217px;
  font-size: 0.9rem;
  border: 1px solid #dcdcdc;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px 0 5px 0;
}
.revisions-columns-active {
  grid-template-columns: 70px 1fr 122px 65px 85px 69px;
  grid-template-areas: 'item1 item2 item3 item4 item5 item6';
}
.revisions-columns-disable {
  grid-template-columns: 70px 1fr 200px;
  grid-template-areas: 'item1 item2 itemNo';
  opacity: 50%;
}
@media (min-width: 768px) {
  .d-none-desk {display: none;}


}

@media (max-width: 768px) {
  .ordenation-button {
    height: 50px!important;
  }

  .revisions-top-bar {
    grid-template-columns: 65px 1fr 217px;
  }

  .d-none-mob {display: none;}
  .revisions-columns-active {
    grid-template-areas: 
     "item4 item5 item3 item6"
     "item1 item2 item2 item2 ";
    grid-template-columns: auto auto auto auto; 
    grid-template-rows: auto auto;

  }
  .revisions-columns-disable {
    grid-template-areas: 
    "item1 item2 item2 "
    "itemNo itemNo itemNo ";
   opacity: 50%;
  }

  .item1 {
    grid-area: item1;
    width: 65px;
    padding: 20px 0 0 0
  }
  .itemNo {
    grid-area: itemNo;
    padding-top: 20px;
    width: 100%;
  }
  
  .item2 {
    grid-area: item2;
    width: 100%;
    padding: 20px 10px 0 0;
  }
  
  .item3 {
    grid-area: item3;
    width: 150px;
  }
  
  .item4 {
    grid-area: item4;
    width: 50px;
  }
  
  .item5 {
    grid-area: item5;
    width: 70px;
    margin: 0 auto;
  }
  
  .item6 {
    grid-area: item6;
  }
}

.style-input-date {
  width: 119px!important;
  margin-left: 5px;
}

.color-buttons {
  display: flex;
  justify-content: space-between;
}
.popover-colors {
  position: absolute;
  box-shadow: 0px 0px 5px 1px #8e8e8e;
}
.red-date {
  background-color: #ffa1a1;
}
.green-date {
  background-color: #a8ffa1;
}
.yellow-date {
  background-color: #ffffa1;
}
.white {
  background-color: #ffffff;
}
.box-popover {
  background-color: red;
}
.main-container-color {
  position: absolute;
}
.disable-scroll {
  overflow: hidden!important;
}
.background-colors {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  background-color: #68686857;
}
.revisions-book-content {
  font-size: 0.9rem;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 7px 0 7px 0;
  width: 100%;
  display: grid;
  position: relative;
  align-items: center;
  input {
    width: 100%;
    padding: 4px 10px;
    border: 1px solid #d8d8d8;
  }
}
//FIM NOVA TELA DE BOOKS REVISION

.actions-score {
  position: absolute;
  right: 0;
  bottom: -45px;
  height: 30px;
  border: none;
  cursor: pointer;
  background-color: #b8b8b8;
  transition: all .2s;
  &:hover {
    transition: all .2s;
    transform: scale(1.05);
    background-color: #858585;
  }
}
.transparent {color: transparent}
.blue {
  background-color: #e9f4f3;
}

.btn-save-info {
  
}

.meta-button {
  display: flex;
  font-weight: bolder;
  flex-direction: column;
}

//ModalDeleteConfirmation
.container-modal {
  background-color: rgba(0, 0, 0, 0.115);
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  .layout-delete {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px #afafaf;
    .buttons {
      display: flex;
      justify-content: right;
    }
  }
}


//ModalDeleteConfirmation
.container-modal-confirmation {
  background-color: rgba(0, 0, 0, 0.115);
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0; //TESTE
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  .layout-delete {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px #afafaf;
    .buttons {
      display: flex;
      justify-content: right;
    }
  }
}

.subContainer-qc {
  border: 1px solid;
}
.container-itens-qc {
  max-height: 410px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
.containerSubQuestion {
  font-size: 0.9rem;
  padding: 15px;
}
.title-subQuestions {
  color: #6e6e6e;
  font-weight: 600;
}
.noSubject {
  padding: 10px;
  width: 100%;
  text-align: center;
  color: #c5c5c5;
}
.bolinhaCount {
  border-radius: 48%;
  padding: 2px 5px;
  background-color: #5e5e5e;
  color: #fff;
}
.subQuestions {
  display: grid;
  grid-template-columns: 31% 31% 35% 1%;
  // align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  background-color: #f1f1f1;
  margin-top: 5px;
  padding: 10px;
  overflow: hidden;
  word-break: break-all;
}
.qtd_questions_size {
  width: 85px!important;
}
.input_questions {
  padding: 2px;
  border: 1px solid #d8d8d8;
}
.element-3-qc {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 15px;
}
.element-1-qc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 20px;
}
@media (max-width: 768px) {
  .actions-score {
    right: 20px;
  }

  .subject-size-input {
    width: 100%;
  }
  .observation-size-input {
    width: 100%;
  }
  
  .subQuestions {
    grid-template-columns: 1fr;
    .element-4-qc {
      grid-row-start: 1;
      grid-row-end: 2;
      text-align: right;
      button {
        text-align: right;
      }
    }
    .element-2-qc {
      grid-row-start: 1;
      grid-row-end: 1;
    }
    .element-1-qc {
      padding-right: 0;
      margin-bottom: 3px;
      word-wrap: break-word;
      overflow: hidden;
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column: 1 / -1;
    }

    .element-3-qc {
      padding-right: 0;
      word-wrap: break-word;
      overflow: hidden;
      grid-row-start: 3;
      grid-row-end: 3;
      grid-column: 1 / -1;
    }
  }
}
.deleteBtnConfirm {
  border: transparent;
  background-color: #102e3b;
  color: #fff;
  padding: 4px 9px;
  border-radius: 3px;
  margin-left: 20px;
  &:hover {
    background-color: #0f2b38;
  }
}
.deleteBtnCad {
  border: transparent;
  background-color: #ffa1a1;
  color: #fff;
  padding: 4px 9px;
  border-radius: 3px;
  &:hover {
    background-color: #fe7e7e;
  }
}
.subContainer-qc-input {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 70px;
  border: 1px solid #e4e4e4;
  padding: 5px;
  gap: 10px;
  input {
    width: 100%;
    padding: 5px;
    border: 1px solid rgb(190, 190, 190);
  }
  button {
    border-radius: 5px;
  }
}
@media (max-width: 768px) {
  .subContainer-qc-input {
    grid-template-columns: 1fr 1fr;
  }
}

.noItens {
  opacity: 0.7;
}
.container-right {
  display: grid;
  grid-template-columns: 1fr 80px;
  align-items: center;
  background-color: #f1f1f1;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px;
  }
}
.bolinhaCount {
  padding: 0px 6px;
  margin: 5px;
  border-radius: 4px;
  text-align: center;
}
.counter-spots {
  background-color: #f1f1f1;
  height: 100%;
  display: grid;
  grid-template-columns: auto 26px;
  align-items: center;
}
.container-icons-qc {
  display: flex;
}
.container-qc {
  color: #5e5e5e;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  font-size: 0.9rem;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 68px 1fr;
  margin-top: 7px;
  img {
    cursor: pointer;
    margin-right: 20px;

    &:hover {
      filter: brightness(0.5);
    }
  }
}
.container-qc-new {
  background-color: #f1f1f1;
  border: 1px solid #c8c8c8;
  color: #929292;
  padding: 10px;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 70px;
  gap: 5px;
  margin-top: 10px;
  input {
    padding: 5px 5px 5px 10px;
    border: 1px solid #c8c8c8;
    background-color: #fff;
  }
  button {
    padding: 5px 7px;
    border-radius: 5px;
  }
}

//AQUI TERMINA A PARTE DE QC

.ordenation-button {
  width: 37px;
  height: 37px;
  border: transparent;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: all 0.2s;
  &:hover {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 0px 3px 0px rgb(212, 212, 212);
    transition: all 0.2s;
  }
}
.container-books-itens {
  height: 350px;
  position: relative;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
.container-informative-itens {
  height: 370px;
  overflow-y: auto;
  .accordion-body {
    display: grid!important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px; 
    box-sizing: border-box; 
    padding: 10px;
  
  }
  .accordion-button:focus {
    box-shadow:none!important;
  }
  .accordion-button:not(.collapsed) {
    background-color: #e7e7e7 !important;
 
  }
  .accordion-button:is(.collapsed) {
    color: #0e2935;
    font-weight: bolder;
    border: none;
    background-color: #f3f3f3;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
  &::-webkit-scrollbar {
    width: 0px;
  }
}

@media (max-width: 768px) {
  .container-books-itens {
    height: 310px;
  }
  .container-informative-itens {
    height: 385px!important;
    .accordion-body {
      grid-template-columns: 1fr;
      .info-header {
        grid-template-columns: 90px 31px 72px 15px 72px;
      }
      .header-accordion-info {
        // grid-template-columns: 72px 31px 72px 15px 72px;
      }
    }
  }
}
.noObservations {
  color: #c5c5c5;
}
.box-itens-revisions {
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
.container-revision {
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  .saveBtnCad {
    background-color: #102e3b;
    max-height: 50px;
  }
  .btnCad {
    margin-left: 10px;
    height: 35px;
    width: 49px;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 2px;
    font-size: 0.8rem;
  }

  p {
    margin: 0;
    font-weight: 600;
  }
  .form-informative {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.children-info {
  display: grid;
  grid-template-columns: 75px 1fr 37px;
  background-color: #f3f3f3;
  align-items: center;
  padding: 10px;
  input {
    border: 1px solid #d8d8d8;
    padding: 7px;
    width: 100%;
    height: 100%;
  }
}
.main-observation {
  width: 100%;
  height: auto;
  max-height: 50px;
  padding: 5px;
  min-height: 50px;
  border: 1px solid #d3d3d3;
}
@media (max-width: 500px) {
  .flex-container {
    flex-direction: column!important;
  }
  .info-header {
    grid-template-columns: 90px 31px 72px 15px 72px;
  }
  
  .header-accordion-info {
    // grid-template-columns: 72px 31px 72px 15px 72px;
  }
  .info-geral .accordion-button {
    padding: 5px!important;
  }
  .btn-at {
    margin-top: 10px;
  }
 
}

.timer {
  font-weight: bold;
  font-size: 8px;
  text-shadow: 0 0 black;
  height: 25px;
  background-color: "#fff";
}

@media (max-width: 768px) {
  .timer {
    // width: 100px;
    height: 20px;
    background-color: "#fff";
  }
}

.info-header {
  font-size: 0.9rem;
  display: grid;
  grid-template-columns: 88px 31px 72px 15px 72px;
  // gap: 5px;
  // padding: 7px;
  border-radius: 5px;
  text-align: center!important;
  width: 100%;
  align-items: center;
  background-color: #f3f3f3;

  input {
    border: 1px solid #d8d8d8;
    padding: 7px;
    width: 100%;
    height: 100%;
    max-height: 30px;
    // margin: 5px;
  }
}
.item-accordion {
  border: 1px solid rgb(223, 223, 223)!important;
}
.shadow {
  box-shadow:0px 7px 8px 1px #ededed !important;
}
.header-accordion-info {
  background-color: #e7e7e7!important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px!important;
  input {
    max-width: 100px;
  }
}
@media (max-width: 768px) {
  //book responsive
  .info-container {
    padding: 5px !important;
    .currentDateInput {
      justify-content: left !important;
    }
    .info-container-date {
      .info-cantainer-date-popup {
        bottom: -173px;
        right: -4px;
      }
      justify-content: left;
      .float-button {
        bottom: 15px;
        left: 98%;
      }
    }
    grid-template-columns: 1fr !important;
  }
}

.info-container {
  font-size: 0.9rem;
  border: 1px solid #dcdcdc;
  background-color: #f3f3f3;
  border-radius: 5px;

  padding: 7px 15px 7px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 120px 1fr 1fr;
  gap: 5px;
  position: relative;
  align-items: center;
  input {
    width: 100%;
    padding: 4px 10px;
    border: 1px solid #d8d8d8;
  }
  .currentDateInput {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .noBreakLine {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
  }
}



.info-container-result {
  font-size: 0.9rem;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 7px 15px 7px 0;
  width: 100%;
  gap: 5px;
  display: grid;
  grid-template-columns: 130px 1fr;
  position: relative;
  align-items: center;
  input {
    width: 100%;
    padding: 4px 10px;
    border: 1px solid #d8d8d8;
  }
  .currentDateInput {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .noBreakLine {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
  }
}

.list-filtered-qc {
  overflow-y: auto;
  top: 41px;
  height: auto;
  min-height: 50px;
  max-height: 300px;
  padding: 10px;
  background-color: #fff;
  z-index: 99;
  width: 100%;
  position: absolute;
  li {
    border: 1px solid #e9e9e9;
    padding: 3px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: #f4f4f4;
    }
  }
}
@media (max-width: 768px){
  .list-filtered {
    top: 49px;
  }
}

.list-filtered {
  overflow-y: auto;
  top: 64px;
  height: auto;
  min-height: 50px;
  max-height: 300px;
  padding: 10px;
  background-color: #fff;
  z-index: 99;
  width: 100%;
  position: absolute;
  li {
    border: 1px solid #e9e9e9;
    padding: 3px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: #f4f4f4;
    }
  }
}
// INFORMATIVE

.info-container-date {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .info-cantainer-date-popup {
    position: absolute;
    width: 156px;
    height: 187px;
    top: 25px;
    left: 0;
    background-color: #fff;
    border: 1px solid #aaaaaa;
    z-index: 99999;
    box-shadow: 1px 2px 7px 0px #b7b7b7;
    border-radius: 7px;
    background-color: #fafae9;
    .title-revision-historic {
      color: grey;
      margin: 12px 0;
    }

    .container-dates-popup {
      height: 127px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }

  .float-button {
    width: 0;
    border: none;
    color: #777777;
    font-weight: 600;
    margin-left: 3px;
    font-size: 15px;
  }
}

//AQUI TERMINA TODO O MODAL DE CONTROL
.w-100 {
  width: 100%;
}

.size-title-table {
  width: calc(100% - 200px)!important;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .topo-quadros {
    padding-bottom: 25px;
  }
  .container-informative-itens {
    height: 385px!important;
    .accordion-body {
      grid-template-columns: 1fr 1fr;
    }
  }
}
.topo-quadros {
  width: 100%;
  text-align: center;
  position: relative;

  .button-score-header {
    position: absolute;
    right: 0;
    top: -3px;
    width: auto;
  }
  .cart {
    position: absolute;
    top: -48px;
    right: 0px;
    width: 62px;
  }
  @media (max-width: 1000px) {
    .button-score-header {
      width: 148px!important;
      }
    }
}
.myBooks {
  td input {
    height: 65px;
    margin-top: 0;
  }
  .border {
    border: 1px solid;
    padding: 5px;
    margin-top: 9px;
    text-align: left !important;
  }
  .notExists {
    margin: 1rem 0 1rem 1rem;
  }
  .t-left {
    text-align: left !important;
  }
  .t-center {
    text-align: center !important;
  }

  .tooltip-inner {
    background-color: #000000bd;
    border: 1px solid rgb(102, 102, 102);
    color: rgb(255, 255, 255);
  }

  .atalhos-table {
    display: flex;
    justify-content: center;
  }
}
.f-right {
  float: right;
}

@media (min-width: 576px) {
  .check-tbl tbody tr {
    height: 80px;
    background-color: #fbfbfb;
  }
  .atalhos-table {
    justify-content: right !important;
  }
}

.box-score {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    margin: 0 20px;
    grid-template-columns: repeat(1, 1fr);
   .info-box-score {
      left: 0px!important;
    }
  }
  .btn-skin {
    border: outset;
    border-color: floralwhite;
    cursor: pointer;
  }
  .btn-skin-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    height: 30px;
    span {
      font-size: 9px;
      padding-right: 2px;
    }
  }
  .box-week {
    border: 1px solid #cdcdcd;
    background-color: #efefef;
    box-shadow: 0px 0px 5px 1px #ebebeb;
    .t-center {
      text-align: center;
    }
    .font-weight-600 {
      font-weight: 600;
      color: #000;
    }
    .style-of-datail {
      flex-direction: column;
      justify-content: center;
      &:hover {
        cursor: pointer;
        background-color: #dbdbdb;
      }
    }
    .b-white {
      background-color: #fff;
    }
    .b-gray {
      background-color: #efefef;
    }

    .detail-week {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 15px;
      }

      .box-one-detail {
        border: 1px solid #cfcfcf;
        display: flex;
        flex-direction: column;
        font-size: 13px;
        justify-content: space-between;
        input {
          border: none;
          border-top: 1px solid #cfcfcf;
          width: 98%;
          text-align: center;
          font-weight: 700!important;
          color: currentColor;
        }
      }
    }
  }
  .input-container {
    position: relative;
    color: #000;
  }
  .input-container:after {
    content: "pts";
    position: absolute;
    bottom: 3px;
    right: 7px;
    font-size: 8px;
    -webkit-text-stroke-width: medium;
    text-shadow: 0 0 black;
  }
  .icon-hrs {
    position: relative;
    color: #000;
    input {
      margin-right:11px!important;
    }
  }
  .icon-hrs:after {
    content: "hrs";
    position: absolute;
    bottom: 5px;
    right: 7px;
    font-size: 8px;
    -webkit-text-stroke-width: medium;
    text-shadow: 0 0 black;
  }


  .info-box-score {
    border: 1px solid #000;
    position: absolute;
    left: -24px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    width: 17px;
    height: 17px;
    background-color: rgb(53, 98, 247);
    color: #fff;
    border-radius: 50%;
    &::after {
      content: "";
      border: 1px solid #455fd0;
      position: absolute;
      left: 12px;
    }
  }
  .one-tip {
    top: 75px;
    &::after {
      width: 27px;
    }
  }
  .two-tip {
    top: 100px;
    &::after {
      width: 30px;
      z-index: 9;
    }
  }
  .three-tip {
    top: 125px;
    &::after {
      width: 27px;
    }
  }
  .four-tip {
    top: 152px;
    &::after {
      width: 18px;
      z-index: 1;
    }
  }
  .five-tip {
    top: 179px;
    &::after {
      width: 14px;
    }
  }
}
@media (max-width: 577px ) {
  .one-tip {
    top: 68px!important;
  }
  .two-tip {
    top: 93px!important;
  }
  .three-tip {
    top: 119px!important;
  }
  .four-tip {
    top: 143px!important;
  }
  .five-tip {
    top: 168px!important;
  }
}

.title-quadro {
  // display: flex;
  // align-items: center;

  .tip {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    width: 20px;
    height: 20px;
    background-color: rgb(53, 98, 247);
    color: #fff;
    border-radius: 50%;
    // position: absolute;
    // right: 0;
    // top: 6px;
  }
}

.historic-table {
  padding: 10px;
  color: #000;
  border-bottom: 1px solid #d5d5d5;
}

// MODAL METAS
.backgroundDark {
  background-color: rgba(0, 0, 0, 0.37);
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 10;
  left: 0;
}

.box-actions {
  border: 1px solid;
  text-align: center;
  border-radius: 5px;
  position: fixed;
  right: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  background-color: #f5f5f5;
  width: 100%;
  max-width: 351px;
  min-height:auto;
  box-shadow: 0px 1px 11px 0px #626262;
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    & button {
      width: 100%;
    }
  }
}

.box-modal {
  border: 1px solid;
  border-radius: 5px;
  position: fixed;
  right: 0;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  background-color: #f5f5f5;
  width: 100%;
  max-width: 450px;
  min-height: 550px;
  box-shadow: 0px 1px 11px 0px #626262;

  .text-bold {
    font-weight: 600;
  }

  .buttons-modal {
    display: flex;
    justify-content: right;
    width: 100%;
    margin-top: 20px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    button {
      width: 25%;
      // margin: 0px 20px 10px 0;
    }
  }
  .message-spots {
    color: #000;
    border: 1px solid #000;
    padding: 4px 3px;
  }
  .modal-bloco {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    color: #000;
    padding: 0px 0px;
    p {
      display: flex;
      align-items: center;
      margin: 0;
    }

    input {
      width: 65px;
      height: 40px;
      margin-right: 10px;
      text-align: center;
      // border: 1px solid black;
      border-radius: 4px;
    }
    .info-bottom {
      width: 90%;
      margin-bottom: 10px;
      text-align: center;
    }
  }
  .lastTwoLines {
    background-color: #ececec;
    padding: 10px 0;
  }
}

.modal-assuntos {
  width: 100%;
  min-width: 350px;
  td {
    border-bottom: none;
  }
  @media (min-width: 576px) {
    .check-tbl tbody tr {
      display: flex;
      align-items: center;
      border: 1px solid #cdcdcd;
    }
  }

  .adjust-subject-modal {
    width: 100%;
    border-bottom: none;
  }
  .scroll {
    max-height: 400px !important;
    overflow-y: auto;
    border-bottom: 1px solid #bbbbbb;
  }
}

.modal-control {
  position: fixed;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 1000px;
  // min-height: 650px;
  // max-height: 650px;
  z-index: 12;
  background-color: #ffffff;
  .title-modal-control {
    text-align: center;
    width: 100%;
    font-weight: bolder;
    padding: 10px 0 20px 0;
  }
  .container-codes {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    gap: 7px;
    padding: 20px;
    background-color: #e5e5e5;
    border: 1px solid #e5e5e5;
  }
  .numberCode {
    border-radius: 2px;
    border: 1px solid #000;
    text-align: center;
    color: #000;
    width: 100%;
    &:hover {
      cursor: pointer;
      transform: scale(1.03);
    }
  }
  .done {
    background-color: #1ad31a;
  }

  .buttons-modal-control {
    margin-right: 24px;
    /* position: absolute; */
    /* right: 20px; */
    padding-bottom: 50px;
  }

  @media (max-width: 1000px) {
    .container-codes {
      grid-template-columns: repeat(12, 1fr);
    }
  }
  @media (max-width: 670px) {
    .container-codes {
      grid-template-columns: repeat(8, 1fr);
    }
  }
}