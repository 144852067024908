.headline-carousel {

    
.testimonial-1 {
    .testimonial-detail {
        margin-top: 0!important;
            img {
                width: 100px!important;
                height: 100px!important;
            }
        }
        .testimonial-pic {
            width: 100px!important;
            height: 100px!important;
        } 
        .testimonial-name {
            font-size: 1.5em!important;
        }
        
        p {
            height: 70px!important;
        }
    }
}



@media(max-width: 768px) {
    .container-top-headline {
      grid-template-columns: 1fr!important;
      gap: 20px!important;
  
    }
    .amount {
        text-align: center!important;
      }
    .items-headline {
      gap: 20px!important;
      grid-template-columns: 1fr!important;
    }
  }
  
  .items-headline {
    display: grid;
    grid-template-columns: 1fr 240px;
    gap: 20px!important;
    iframe {
      width: 100%;
    }
  }
  .container-top-headline {
    display: grid;
    grid-template-columns:  240px 1fr;
    gap: 20px!important;
      .img-product-headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 250px;
        }
      }
    .amostra {
        p {
            text-align: center;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
            background-color: #f7f7f7;
            width: 150px;
            transition: all 0.2s;
            &:hover {
                background-color: #dadada;
                cursor: pointer;
                transition: all 0.2s;
            }
        }
    }
    .amount {
      text-align: right;
    }
    .addCart {
      text-align: center;
    }
    .manchete-product-actions {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        margin-bottom: 20px;
    }

    .right-content-manchete-product {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .container-saude {
    display: flex;
    gap: 20px;

    .iFrame {
      width: 200px;
      min-width: 200px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .iFrame {
        width: 100%;
      }
    }
  }