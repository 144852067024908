.removeImageContact {
  border: 1px solid #e9e9e9;
  color: #c7c7c7;
  border-radius: 3px;
  &:hover {
    color: #a6a6a6;
  }
}
.banner-whats {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(200, 200, 200, 1) 100%
  );
  height: auto;
  padding: 50px 0;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .limit-of-text {
    max-width: 1000px;
  }
  a {
    color: #000;
  }
  h2 {
    font-weight: bolder;
    color: #000;
    font-size: 33px;
  }
  p {
    color: rgb(0, 0, 0);
    font-size: 29px;
  }
  @media (max-width: 1000px) {
    height: auto;
    margin-bottom: 100px;
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
}
/* FORMULARIO */
.btn-ale {
  border: 1px solid #bfbfbf;
  font-weight: bold;
  transition: all 0.2s;
  &:hover {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary);
    color: #fff;
    transition: all 0.2s;
  }
}
.dzForm {
  input,
  textarea {
    border-radius: 0 !important;
  }
}

.sugestion-three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  input {
    width: 100% !important;
  }
}

.sugestion-twice-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  input {
    width: 100% !important;
  }
}

.input-duble-first {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  input {
    width: 100% !important;
  }
}
.input-duble-second {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  input,
  select {
    width: 100% !important;
  }
}

// .input-duble {
// 	display: flex;
// 	flex-direction: column;
// 	border-radius: 0;
// 	input , select{
// 		width: 100%!important;
// 	}
// 	:nth-child(1){
// 		margin-bottom: 18px;
// 	}
// 	@media (min-width: 768px) {
// 		flex-direction: row;
// 		input , select {
// 			width: 45%!important;
// 		}
// 		:nth-child(1){
// 			margin: 0 18px 0 0;
// 		}
// 	}
// }

.contact-wraper1 {
  margin-bottom: 60px;
  position: relative;
  background-position: -260px center;

  .contact-info {
    padding: 50px 30px;
  }

  // &:before{
  // 	content: "";
  // 	height: 100%;
  // 	width: 50%;
  // 	position: absolute;
  // 	left: 0;
  // 	top: 0;
  // 	z-index: -1;
  // 	background: linear-gradient(to right, rgba(26, 22, 104, 0.5) 0%, rgb(26, 22, 104) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // }
  &:after {
    content: "";
    height: 100%;
    width: 50%;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  @include respond("tab-land") {
    .contact-info {
      padding: 50px 0px;
    }
   
  }
  @include respond("phone-land") {
    .sugestion-three-column {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
    .input-group {
      margin-bottom: 10px;
    }
  }
  @include respond("tab-port") {
    background-position: center;
    background-size: cover;
    margin-bottom: 0;

    &:after {
      content: none;
    }
    &:before {
      height: 100%;
      width: 100%;
    }
    .contact-area1 {
      margin-top: 0;
      margin-bottom: 0;
    }
    .contact-info {
      padding: 50px 0px 20px;
    }
  }
}
.contact-area1 {
  padding: 50px;
  background: #fff;
  // box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.07);
  @include respond("phone") {
    padding: 30px;
  }
}
.map-iframe {
  height: 450px;
  @include respond("phone") {
    height: 250px;
  }
}
form {
  .g-recaptcha {
    @include respond("phone") {
      transform: scale(0.7);
      -moz-transform: scale(0.7);
      -o-transform: scale(0.7);
      -webkit-transform: scale(0.7);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }
}
