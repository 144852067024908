.raffle-container {
    width: 100%;
    border: 1px solid rgb(19, 19, 19);
}


.btn-default {
    height: 40px;
    width: 100px;
}

.content-raffle {
    display: flex;
    justify-content: space-between;
    flex-direction: column;  

    span {
        margin-right: 10px;
    }

    input {
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        width: 60px;
        height: 40px;
        padding: 5px;
    }
}

.restore-cad {
    .select-restore-cad {
        border-radius: 4px;
        width: 200px;
        height: 40px;
    }
    button {
       @extend .btn-default;
    }
}

.first-content-raffle {
    display: flex;

    button {
        margin-left: 30px;
        @extend .btn-default;
    }
}

.container-raffle-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f7f7;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;

    .raffle-btns {
        display: flex;
    }
    button {
        width: 36px;
        height: 33px;
        border: transparent;
        background-color: #ffa1a1;
        color: #fff;
    }

    .not-read {
        color: red;
    }

    .read {
        color: green;
    }

    .ico-btn-check {
        width: 36px;
        height: 33px;
        color: #fff;
        display: flex;
        margin-bottom: 0;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        background-color: #a1ffb5;
    }

    .ico-btn-close {
        background-color: #ffa1a1;
    }
}

.base-flex {
    display: flex;
    align-items: center;
}


.responsive-checkbox {
    display: flex;
}

.responsive-checkbox > input[type="radio"] {
    -webkit-appearance: none; /* Para navegadores baseados no WebKit */
    -moz-appearance: none;    /* Para Firefox */
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #cfcfcf;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }
  
  /* Estilização para quando estiver selecionado */
 .responsive-checkbox > input[type="radio"]:checked::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.disabled {
    opacity: 0.50;
}

@media (max-width: 600px) {
    .responsive-checkbox {
        flex-direction: column;
    }
}