.container-collaboration {
    padding: 50px;
    text-align: justify;
    .box-image {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr;
        font-size: 1rem;
        margin-bottom: 70px;
    }
    img {
        width: 100%;
    }
    .bottom-collaboration{ 
        font-size: 16px;
    }
    .order-invert {
        > div:nth-child(1) {
            order: 1!important;
        }
        > div:nth-child(2) {
            order: 2!important;
        }
    }

    .header-collaborations{
        display: flex;
    }
}

@include respond('tab-port'){ //max 991
    .container-collaboration {
        padding: 20px!important;
        .box-image {
            display: grid; /* Adiciona display: grid para a classe .box-image */
            font-size: 1rem!important;
            grid-template-columns: 1fr;
          }
          .order-invert {
            > div:nth-child(1) {
                order: 2!important;
            }
            > div:nth-child(2) {
                order: 1!important;
            }
        }
    }
}
@include respond('tab-land'){ //max 1280
    .container-collaboration {
        .box-image {
            font-size: 1.1vw;
        }
    }
}